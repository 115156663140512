import React from 'react'

import { SubTitle } from 'ui'

import { useStaticQuery, graphql } from 'gatsby'

const Item = ({ file, name }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `,
  )

  const getImageNameFromSrc = src => {
    return src?.split('/').pop().split('.')[0]
  }

  const item = allFile.edges.find(
    item =>
      getImageNameFromSrc(item.node.publicURL) === getImageNameFromSrc(file),
  )

  const publicURL = item?.node?.publicURL

  return (
    <li style={{ maxWidth: 'fit-content', textDecoration: 'underline' }}>
      <a href={publicURL} target="_blank">
        <SubTitle text={name} variant="small" />
      </a>
    </li>
  )
}

export default Item
