import React, { useEffect, useState } from 'react'

import { Title } from 'ui'
import Item from './Item'

import aboutusSettings from 'site/aboutus/settings.json'

const PresentationLinks = () => {
  const [presentationLinks, setPresentationLinks] = useState([])

  useEffect(() => {
    for (const item of aboutusSettings.presentations_items) {
      if (!item.is_show_on_website) {
        setPresentationLinks(prev => [...prev, item])
      }
    }
  }, [])

  return presentationLinks.length > 0 ? (
    <div className="row mt-5 mb-5">
      <div className="col-xxl-8 col-xl-8 col-sm-8 col-md-8">
        <Title text="Check out our presentations" variant="small" />
        <ul className="col">
          {presentationLinks.map(({ file, name }, i) => (
            <Item
              file={file}
              name={name}
              key={`privacy-policy-presentation-link-${i}`}
            />
          ))}
        </ul>
      </div>
    </div>
  ) : null
}

export default PresentationLinks
