import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import PrivacyPolicy from 'components/PrivacyPolicy'

const Privacy = ({ location, data }) => {
  return (
    <Layout location={location} className="terms" title="Privacy Policy">
      <SEO title="Privacy Policy" location={location.href} />
      <PrivacyPolicy />
    </Layout>
  )
}

export default Privacy
