import React from 'react'

import convertToHTML from 'markdown-to-html-converter'

import { body } from 'site/settings/privacy.json'

import PresentationLinks from './PresentationLinks'

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xxl-8 col-xl-8 col-sm-8 col-md-8">
          <div dangerouslySetInnerHTML={{ __html: convertToHTML(body) }} />
        </div>
      </div>
      <PresentationLinks />
    </div>
  )
}

export default PrivacyPolicy
